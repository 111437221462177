
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_lm6vo_1sk5h_149",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_1sk5h_156",
  "layout": "awsui_layout_lm6vo_1sk5h_161",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_1sk5h_167",
  "layout-main": "awsui_layout-main_lm6vo_1sk5h_171",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_1sk5h_177",
  "unfocusable": "awsui_unfocusable_lm6vo_1sk5h_182",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_1sk5h_186",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_1sk5h_191",
  "content-wrapper": "awsui_content-wrapper_lm6vo_1sk5h_195",
  "content-overlapped": "awsui_content-overlapped_lm6vo_1sk5h_199",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_1sk5h_203"
};
  